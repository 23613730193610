document.addEventListener('DOMContentLoaded', function(){
    console.log('hi dom 👋');

    // team collapse content
    let root = document.documentElement;
    const setPanelHeight = el => {
        // const innerPanel = el.parentElement.querySelector('.panel-inner');
        let panelHeight = el.parentElement.querySelector('.team-member-container').clientHeight;
        root.style.setProperty('--panelHeight', panelHeight + "px");
    }
    const setPanelHeightResize = () => {
        // const innerPanel = el.parentElement.querySelector('.panel-inner');
        let activePanel = document.querySelector('.active.team-grid-col .team-member-container');
        if (activePanel) {
            let panelHeight = activePanel.clientHeight;
            root.style.setProperty('--panelHeight', panelHeight + "px");
        }
    }
    // set tab active state
    const setActive = el => {

        let closeBtn = el.parentElement.querySelector('.close-panel');
        let shouldClose = false;

        if(el.parentElement.classList.contains('active')) {
            el.parentElement.classList.remove('active');
            el.parentElement.querySelector('.team-member-panel').setAttribute('aria-hidden', true);
            el.parentElement.querySelector('.team-member-panel').setAttribute('tab-index', -1);
            shouldClose = true;
        } else {
            [...el.parentElement.parentElement.children].forEach(sib => {
                sib.classList.remove('active');
                sib.querySelector('.team-member-panel').setAttribute('aria-hidden', true);
                sib.querySelector('.team-member-panel').setAttribute('tab-index', -1);

            });
            el.parentElement.classList.add('active');
            el.parentElement.querySelector('.team-member-panel').setAttribute('aria-hidden', false);
            el.parentElement.querySelector('.team-member-panel').setAttribute('tab-index', 1);
            shouldClose = false;
        }

        if (closeBtn) {
            closeBtn.addEventListener('click', function(e){
                let el = e.currentTarget;
                e.preventDefault();
                if(el.parentElement.parentElement.parentElement.classList.contains('active')) {
                    el.parentElement.parentElement.parentElement.classList.remove('active');
                    el.parentElement.parentElement.parentElement.querySelector('.team-member-panel').setAttribute('aria-hidden', true);
                    el.parentElement.parentElement.parentElement.querySelector('.team-member-panel').setAttribute('tab-index', -1);
                    document.querySelectorAll('.team-grid-col').forEach( tabGrid => {
                        tabGrid.classList.remove('notransition');
                    });
                    shouldClose = true;
                }
            });
        }

        if (!shouldClose) {
            document.querySelectorAll('.team-grid-col').forEach( tabGrid => {
                tabGrid.classList.add('notransition');
            });
            var anchor = document.querySelector('.active .anchor');
            anchor.scrollIntoView(
                {
                    alignToTop: true,
                    behavior: 'smooth', // Defines the transition animation. default: auto, options: auto|smooth
                }
            );
        }

        if (shouldClose) {
            document.querySelectorAll('.team-grid-col').forEach( tabGrid => {
                tabGrid.classList.remove('notransition');
            });
        }
    }
    const tabs = document.querySelectorAll('.team-member-tab');
    tabs.forEach(el => el.addEventListener('click', e => setActive(el)));
    tabs.forEach(el => el.addEventListener('click', e => setPanelHeight(el)));
    window.addEventListener('resize', () => {
        setPanelHeightResize();
    });

});